import Image from "next/image";
import React from "react";
import { Logo } from "./Logo";

interface HeaderProps {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <>
      <div className="mx-10 my-8 mb-12 text-center">
        <div className="w-32 mx-auto ">
          <Logo />
        </div>
      </div>
    </>
  );
};
