import { BillbeeOrderState } from "../../model/Order";
import { OrderItem } from "../../model/OrderItem";
import { SupportCaseStatus } from "../../model/SupportCase";
import { supportCaseItemReducer, SupportCaseItemStateAction, SupportCaseStateItem } from "./SupportCaseItemState";

export interface SupportCaseState {
  email?: string;
  ordernumber: string;
  orderState: BillbeeOrderState | "unknown";
  country: string;
  agreedTerms: string;
  invoiceDate?: string;
  status: SupportCaseStatus;
  items: SupportCaseStateItem[];
  selectedItem?: SupportCaseStateItem;
  customerMessage?: string;
}

export type SupportCaseStateAction =
  | {
      type: "continue";
      supportCase: SupportCaseState;
    }
  | {
      type: "reset";
      ordernumber: string;
      orderState: BillbeeOrderState;
      country: string;
      agreedTerms: string;
      invoiceDate?: string;
      customerMessage: string | undefined;
      orderItems: OrderItem[];
      status: SupportCaseStatus;
    }
  | {
      type: "select-item";
      item?: SupportCaseStateItem;
    }
  | {
      type: "update-item";
      item: SupportCaseStateItem;
      itemAction: SupportCaseItemStateAction;
      // always reset when an item is updated
      agreedTerms?: string;
    }
  | {
      type: "update-customer-message";
      customerMessage: string | undefined;
    }
  | {
      type: "update-agree-terms";
      agreedTerms: string;
    }
  | {
      type: "update-status";
      status: SupportCaseStatus;
    };

export function supportCaseStateReducer(state: SupportCaseState, action: SupportCaseStateAction): SupportCaseState {
  switch (action.type) {
    case "reset":
      return {
        ordernumber: action.ordernumber,
        orderState: action.orderState,
        country: action.country,
        agreedTerms: action.agreedTerms,
        invoiceDate: action.invoiceDate,
        customerMessage: action.customerMessage,
        status: action.status,
        items: action.orderItems.map((orderItem) => ({
          product: orderItem.product,
          purchaseQuantity: orderItem.quantity,
          returnQuantity: 0,
          reason: undefined,
          isComplaint: undefined,
          solution: undefined,
          complaintDescription: undefined,
          complaintImages: [],
          batchNumber: undefined,
          returnFee: undefined,
          returnNotice: [],
          returnConditions: [],
          returnInstructions: [],
          returnParcelService: undefined,
        })),
      };
    case "continue":
      return action.supportCase;

    case "update-customer-message":
      return {
        ...state,
        customerMessage: action.customerMessage,
      };

    case "update-agree-terms":
      return {
        ...state,
        agreedTerms: action.agreedTerms,
      };

    case "update-status":
      return {
        ...state,
        status: action.status,
      };

    case "select-item":
      if (action.item && !state.items.includes(action.item)) {
        throw new Error("This is a bug. The item to be selected is not an item of the current order.");
      }
      return {
        ...state,
        selectedItem: action.item,
      };

    case "update-item":
      if (!state.items.includes(action.item)) {
        throw new Error("This is a bug. The item to be updated is not an item of the current order.");
      }
      const updatedItem = supportCaseItemReducer(action.item, action.itemAction);

      const updatedState = {
        ...state,
        selectedItem: state.selectedItem === action.item ? updatedItem : state.selectedItem,
        items: state.items.map((oldItem) => (oldItem === action.item ? updatedItem : oldItem)),
        // always reset when an item is updated
        agreedTerms: "",
      };

      return updatedState;

    default: {
      throw new Error("Undhandled SupportCaseAction");
    }
  }
}
