import { GenericImage } from "../../model/GenericImage";
import { LocalizedString } from "../../model/LocalizedString";
import { Product } from "../../model/Product";

export interface SupportCaseStateItem {
  isFirstDhlSpeditionsItem?: boolean; // special usecase
  product: Product;
  purchaseQuantity: number;
  returnQuantity: number;
  reason: string | undefined;
  isComplaint: boolean | undefined;
  batchNumber?: string;
  solution: string | undefined;
  complaintDescription?: string;
  complaintImages: GenericImage[];
  // Add directly here from product
  returnFee?: number;
  // Add directly here from product
  returnParcelService?: {
    _id: string;
    name: string;
    isSelfService: boolean;
    returnsLabelLink: string;
    returnsLabelText: LocalizedString[];
  };
}

export type SupportCaseItemStateAction =
  | {
      type: "select-product";
    }
  | {
      type: "change-return-quantity";
      quantity: number;
    }
  | {
      type: "change-reason";
      reason: string;
      isComplaint: boolean;
      solution: undefined;
    }
  | {
      type: "change-solution";
      solution: string;
    }
  | {
      type: "change-batchNumber";
      batchNumber: string;
    }
  | {
      type: "change-complaintDescription";
      complaintDescription: string;
    }
  | {
      type: "change-complaintUpload";
      complaintImage: GenericImage;
    }
  | {
      type: "delete-complaintUpload";
      complaintImageId: string;
    }
  | {
      type: "reset-item";
    };

export function supportCaseItemReducer(
  state: SupportCaseStateItem,
  action: SupportCaseItemStateAction,
): SupportCaseStateItem {
  switch (action.type) {
    case "select-product":
      return { ...state };
    case "change-return-quantity":
      return { ...state, returnQuantity: action.quantity };
    case "change-reason":
      return {
        ...state,
        reason: action.reason,
        isComplaint: action.isComplaint,
        // ! Set this to undefined, so user has to make a new choice for solution
        solution: undefined,
      };
    case "change-solution":
      return { ...state, solution: action.solution };
    case "change-batchNumber":
      return { ...state, batchNumber: action.batchNumber };
    case "change-complaintDescription":
      return { ...state, complaintDescription: action.complaintDescription || "" };
    case "change-complaintUpload":
      const complaintImages = [...state.complaintImages, action.complaintImage];
      return {
        ...state,
        complaintImages,
      };
    case "delete-complaintUpload":
      return {
        ...state,
        complaintImages: state.complaintImages.filter((item) => item.id !== action.complaintImageId),
      };
    case "reset-item":
      return {
        ...state,
        reason: undefined,
        returnQuantity: 0,
        complaintImages: [],
        batchNumber: undefined,
        complaintDescription: undefined,
        solution: undefined,
        isComplaint: undefined,
      };
    default: {
      throw new Error("Undhandled SupportCaseItemAction");
    }
  }
}
