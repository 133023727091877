export function getTippContent(isJsx = false): string | JSX.Element {
  const content =
    "Du kannst gerne mehrere Artikel in einem Paket retournieren. Dann brauchst du nur ein Label pro Paket ausdrucken.";
  return isJsx ? (
    <>
      👋 <strong>Tipp:</strong> {content}
    </>
  ) : (
    `👋 <strong>Tipp:</strong> ${content}`
  );
}

// consider using https://www.npmjs.com/package/html-react-parser
export function isomorphicJsxHtml(
  content: string,
  isJsx = false,
  wrapperAttributes = { className: "" },
): string | JSX.Element {
  return isJsx ? (
    <div {...wrapperAttributes} dangerouslySetInnerHTML={{ __html: content }}></div>
  ) : (
    `<div>${content}</div>`
  );
}

export function getForeignCountryReturnInfo(
  isJsx = false,
  wrapperAttributes = { className: "" },
): string | JSX.Element {
  const content = `
  <p>Bitte beachte, dass die Kosten für Retouren aus dem Ausland grundsätzlich vom Käufer zu tragen sind. Die ursprünglichen Versandkosten (Warenversand zu dir) werden dir bei der Gutschrift wieder erstattet.</p>
  <p>Bei Teil-Rücksendungen aus dem Ausland werden die ursprünglichen Versandkosten entsprechend anteilig zurück erstattet</p>
  <p>Du kannst das Paket an diese Adresse versenden:</p>
  <p>
    Ehrenkind <br />
    Schulze-Delitzsch-Str. 11 <br />
    46359 Heiden <br />
    Deutschland
  </p>
  <p>Bitte gib den Namen des Bestellers und die Bestellnummer an, sodass eine korrekte Zuordnung erfolgen kann.</p>
`;
  return isomorphicJsxHtml(content, isJsx, wrapperAttributes);
}
